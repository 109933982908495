$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scrollTo(target);
            return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 60; // Distance from Browserbottom where the animation should start

    function fadeInElements(){
        var viewPort = $(window).scrollTop() + $(window).height();

        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top;

            if ((elementTop + offset) <= viewPort) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // *  navMobile
    // *
    // *
    $(".js-nav-mobile-button").click(function() {
        $(".js-nav-mobile").toggleClass("active");
    });

    $(window).scroll(function() {
        if($(".js-nav-mobile").hasClass("active")) {
            $(".js-nav-mobile").removeClass("active");
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * videoSlider
	// *
	// *
	var $videoSlider = $(".js-video-slider");

	if ($videoSlider.length){
		$videoSlider.each(function(){
            var $sliderImages = $(this).find(".js-video-slider-images"),
                $sliderContent = $(this).find(".js-video-slider-content");

			$sliderImages.slick({
				fade: false,
				dots: false,
				infinite: true,
				arrows: true,
				autoplay: false,
				speed: 1000,
                centerMode: true,
                centerPadding: 0,
                slidesToShow: 3,
				prevArrow: $(this).find(".js-video-slider-nav-prev"),
				nextArrow: $(this).find(".js-video-slider-nav-next"),
                asNavFor: $sliderContent
			});

            $sliderContent.slick({
				fade: true,
				dots: false,
				infinite: true,
				arrows: false,
				autoplay: false,
				speed: 1000,
                asNavFor: $sliderImages
			});
		});

        // set width off content
        var slideWidth = $(this).find(".slick-slide").width();
        $(".js-video-slider-inner").css("width", slideWidth);

        $(window).resize(function() {
            var slideWidth = $(".js-video-slider").find(".slick-slide").width();
            $(".js-video-slider-inner").css("width", slideWidth);
        });
	}



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * logoCarousel
	// *
	// *
	var $logoCarousel = $(".js-logo-carousel");

	if ($logoCarousel.length){
		$logoCarousel.each(function(){
			$(this).slick({
				fade: false,
				dots: false,
				infinite: true,
				arrows: true,
				autoplay: false,
				speed: 1000,
                slidesToShow: 5,
                slidesToScroll: 5,
				prevArrow: $(this).parents(".js-logo-carousel-wrapper").find(".js-logo-carousel-nav-prev"),
				nextArrow: $(this).parents(".js-logo-carousel-wrapper").find(".js-logo-carousel-nav-next"),
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4
                        }
                    },
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 370,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
			});
		});
	}



    $('[data-fancybox]').fancybox({
      media : {
        youtube : {
          url: "//www.youtube-nocookie.com/embed/$4"
        }
      }
    });
});
